@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');

:root {
  --clr-primary-black: linear-gradient(85deg, #434343, #262626);
  --clr-green: green;
}

html {
  background-color: #17141d;
  overflow: auto;
  font-family: 'Helvetica', sans-serif;
}

body {
  background: url('https://images.unsplash.com/photo-1428223501723-d821c5d00ca3?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDQ1OTh8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjYzMjc4MTA&ixlib=rb-1.2.1&q=85');
  background-size: cover;
  overflow: auto;
}

.hide {
  display: none;
}

/*UTILITY CLASSES */
.flow-content-lg > * + * {
  @apply mt-7;
}

.flow-content > * + * {
  @apply mt-3;
}

.flow-content-sm > * + * {
  @apply mt-1;
}

.heading {
  @apply text-2xl font-bold;
}

.sub-heading {
  @apply text-xl font-bold;
}

.readable-text {
  text-shadow: 1px 1px 2px rgb(0 0 0 / 10%), -1px -1px 2px rgb(0 0 0 / 10%), -1px 1px 2px rgb(0 0 0 / 10%),
    1px -1px 2px rgb(0 0 0 / 10%);
}

.colorful-one {
  background-image: linear-gradient(to right, #16222a 0%, #3a6073 51%, #16222a 100%);
  transition: 0.5s;
}

.colorful-one:hover {
  background-position: right center;
  background-image: linear-gradient(to right, #16222a 0%, #16222a 51%, #3a6073 100%);
}

.main-background {
  background: #fff;
  color: #232323;
  align-self: auto;
  gap: 3px;
  border: 1px solid rgba(35, 35, 35, 0.2);
}

/*STICKY AREA*/
.sticky-area::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.sticky-area::-webkit-scrollbar-thumb {
  background-color: #434343;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
}

.card-list::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d);
}

/* SEARCH SECTION */
.search-option {
  color: rgba(35, 35, 35, 0.5);
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.search-option:first-of-type {
  margin-left: 16px;
}

.search-option:last-of-type {
  margin-right: 16px;
}

.search-option:after,
.search-option--active:after {
  content: '';
  width: 0px;
  height: 3px;
  display: block;
  background: #338fc9;
  transition: 50ms;
}

.search-option:hover:after {
  width: 100%;
}

.search-option--active:after {
  content: '';
  width: 0px;
  height: 3px;
  display: block;
  background: #338fc9;
  width: 100%;
}

.search-option--active {
  color: #338fc9 !important;
}

.search {
  @apply grid items-center;
  grid-template-columns: 1fr 60px;
}

.card {
  min-width: 250px;
  min-height: 300px;
  max-height: 300px;
  box-shadow: -2rem 0 3rem -2rem #000;
  padding: 1.5rem;
  border-radius: 16px;
  margin: 0;
  font-weight: 500;
  display: grid;
}

.card--image {
  grid-template-rows: 1fr 40%;
}

.card-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.card-list {
  max-width: 70vw;
}

.card-list::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
}

.card:hover {
  transform: translate(-0.5rem, -1rem) rotate(10deg);
  opacity: 1;
}

.card:hover ~ .card {
  transform: translateX(90px);
}

.card:not(:first-child) {
  margin-left: -90px;
}

.card:first-child {
  margin-left: 50px;
}

/**
LOADING SPINNER CSS
**/
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

.lds-hourglass--blue::after {
  border: 32px solid #338fc9;
  border-color: #338fc9 transparent #338fc9 transparent;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

/**SETTINGS SECTION ** /

/** radio buttons **/
input[type='radio'] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  @apply bg-blue-600;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type='radio']:checked + label {
  @apply text-blue-600;
}

/* TOASTR */
.Toastify__toast--warning {
  background: linear-gradient(85deg, #434343, #262626) !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: bolder !important;
}

.footer-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10%);
}

.order-last {
  order: 1;
}

.centering {
  align-items: center;
}

.resize {
  width: 24px;
}

.center-glass {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.white-svg svg path {
  fill: white;
}

.red-svg svg path {
  fill: red;
}

.green-svg svg path {
  fill: #338fc9 !important;
}

.left-sidebar-icon-menus {
  top: 100px;
}

.black-text {
  color: #000;
}

/* REACT DATE TIME PICKER */

.react-datepicker.react-datepicker {
  display: flex;
}

/*
COLORS
*/

.default-color {
  background-color: #fab632;
  color: #fff;
}

.color-one {
  background-color: #f43a32;
  color: white;
}

.color-two {
  background: #f8338c;
  color: white;
}

.color-three {
  background-color: #9fd455;
  color: white;
}

.color-four {
  background-color: #7dceb7;
  color: white;
}

.color-five {
  background-color: #5bc1ec;
  color: white;
}

.color-six {
  background-color: #a064af;
  color: white;
}

.color-seven {
  background-color: #e160ab;
  color: white;
}

.green-color {
  background: #71a44c;
  color: #fff;
}

.green-color {
  fill: black;
}

.yellow-color {
  background-color: #bfc061;
  color: #333;
}

.yellow-color .white-svg svg path {
  fill: black;
}

.btn-background {
  position: relative;
  padding: 0.8rem 1.5rem;
  font-family: 'Helvetica', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: black;
  text-decoration: none;
  background-color: white;
  border: transparent;
  outline: transparent;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  animation: glow 16s linear infinite;
  z-index: 999;
}

.btn-gradient {
  color: white;
  background: linear-gradient(90deg, #338fc9, #232323, #338fc9);
  background-size: 300%;
  border-radius: 1.5em;
  z-index: 999;
}

.btn-gradient::before {
  position: absolute;
  content: '';
  top: -5px;
  left: -5px;
  bottom: -5px;
  right: -5px;
  z-index: -1;
  background: inherit;
  background-size: inherit;
  border-radius: 4em;
  opacity: 0;
  transition: 0.5s;
}

.btn-gradient:hover::before {
  opacity: 1 !important;
  filter: blur(20px) !important;
  animation: glow 16s linear infinite !important;
}

@keyframes glow {
  to {
    background-position: 300%;
  }
}

.falafel {
  max-width: 720px;
  width: 75%;
}

.wizzi {
  width: 100%;
}

.wizzos {
  min-width: none;
}

.wheel-details {
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  border-radius: 8px;
}

.focused-search:focus {
  background: rgb(248, 248, 248);
  transition: 0.2s;
}

.button-almostwhite {
  background: rgb(248, 248, 248);
}

.white-border {
  background-color: rgba(35, 35, 35, 0.4);
  border: 2px solid #fff;
}

.margin-top-spec {
  margin-top: 8px;
}

.search-section {
  min-height: 350px;
  gap: 30px;
}

.border-style-one {
  border: 1px solid #16222a;
}

.border-style-two {
  border: 1px solid #e7e8eb;
}

.black-svg svg path {
  fill: black;
}

.border-shadow-pr {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  top: -50px;
  left: -40px;
  z-index: 1;
  border-radius: 5px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  font-size: 14px;
}

.tooltip .tooltiptext--right {
  top: -10px;
  left: 25px;
}

.tooltip .tooltiptext--sn-icons {
  left: -125px;
  top: 0px;
}

.tooltip .tooltiptext--color-change {
  top: 0px;
  left: 25px;
  font-size: 14px;
  white-space: nowrap;
}
img::selection {
  visibility: hidden;
}

.columnize-it {
  flex-direction: column;
}

.fixated {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.m-auto-perso {
  margin: 0 auto;
}

.helveticIt {
  font-family: 'Helvetica', sans-serif;
}

.radiant {
  border-radius: 1.5rem;
}

.border-remove {
  border: none !important;
}

.super-color path {
  fill: #338fc9 !important;
}

.little-margin-left {
  margin-left: -1px;
}

.css-is-cool {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shawarma {
  padding: 2px 6px !important;
  white-space: nowrap;
  width: fit-content !important;
  border-radius: 5px;
  top: -75%!important;
  left: 50%!important;
  transform: translateX(-50%) translateY(-100%)!important;

}

.shawarma:nth-of-type(3n) {
  left: -50px;
}

@media screen and (max-width: 731px) {
  .falafel,
  .perso {
    width: 100% !important;
  }

  .shortcut {
    padding: 8px;
  }

  .shortcut-icon-container > div > span > img {
    width: 18px !important;
    height: 18px !important;
  }

  .addShortcut {
    width: 42px !important;
    height: 42px !important;
    font-size: 1.2rem !important;
  }
}

.settings-icon-div {
  position: relative;
}

.settings-icon-div::after {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  top: 0;
  background-color: white;
  filter: blur(20px) !important;
  z-index: 1;
  display: none;
}

.settings-icon-div svg {
  position: relative;
  z-index: 3;
}

.settings-icon-div:hover::after {
  display: block;
}
